.text-editor {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text-editor.disabled {
  pointer-events: none;
}

.text-editor.disabled .ql-toolbar {
  display: none;
}

.text-editor.disabled .ql-container {
  overflow: hidden;
}

.text-editor .quill * {
  border: none !important;
}

.text-editor .ql-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.text-editor .ql-editor h1 {
  font-size: 24px;
  font-weight: 700;
}

.text-editor .ql-editor h2 {
  font-size: 20px;
  font-weight: 600;
}

.text-editor .ql-editor h3 {
  font-size: 18px;
  font-weight: 500;
}

.text-editor .ql-editor p,
.text-editor .ql-editor ol,
.text-editor .ql-editor ul {
  font-size: 14px;
}

.text-editor .ql-editor p {
  padding: 2px 0;
}

.text-editor .ql-editor ol,
.text-editor .ql-editor ul {
  padding: 8px 0 8px 12px;
}
