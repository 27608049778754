@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  @apply h-screen;
}

#root > div {
  @apply h-screen;
}

@layer base {
  body {
    @apply text-gray-500 dark:text-gray-400 text-sm bg-gray-100 dark:bg-gray-900 leading-normal;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  .h1 {
    @apply text-4xl font-bold text-gray-900 dark:text-gray-100;
  }

  h2,
  .h2 {
    @apply text-3xl font-bold text-gray-900 dark:text-gray-100;
  }

  h3,
  .h3 {
    @apply text-2xl font-semibold text-gray-900 dark:text-gray-100;
  }

  h4,
  .h4 {
    @apply text-xl font-semibold text-gray-900 dark:text-gray-100;
  }

  h5,
  .h5 {
    @apply text-lg font-semibold text-gray-900 dark:text-gray-100;
  }

  h6,
  .h6 {
    @apply text-base font-semibold text-gray-900 dark:text-gray-100;
  }

  hr {
    @apply border-gray-200 dark:border-gray-600;
  }

  .heading-text {
    @apply text-gray-900 dark:text-gray-100;
  }

  .text-foreground {
    @apply text-inherit !important;
  }

  .triangle {
    border-width: 5px;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #dfe9fa;
  height: 0.2rem;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: grab;
  margin-top: -6px; /* Centers thumb on the track */
}

.toll-marker {
  background-image: url("/public/img/toll-marker.png");
  background-size: cover;
  width: 30px;
  height: 40px;
  cursor: pointer;
}
