@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-Light-BF66175e9730f02.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-Regular-BF66175e972ac1c.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-Medium-BF66175e9723b7a.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("TestFoundersGrotesk-Semibold-BF66175e972c958.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-Bold-BF66175e9700615.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-LightItalic-BF66175e972b410.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-RegularItalic-BF66175e97545bf.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-MediumItalic-BF66175e972ebe1.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("TestFoundersGrotesk-SemiboldItalic-BF66175e973bc3b.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./TestFoundersGrotesk-BoldItalic-BF66175e971a18a.otf");
  font-weight: 700;
  font-style: italic;
}
