.end-content input+span {
    position: absolute;
    top: 8px;
}

/* .picker-list > div > div:first-child .map-input+.map-delete {
    display: none !important;
}

.picker-list > div > div:last-child .map-input+.map-delete {
    display: none !important;
} */

/* .map-input:focus+.map-delete {
    display: block !important;
}

.map-delete:hover {
    display: block !important;
} */